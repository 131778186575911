body{
    width: 100%;
    height: 100%; 
}
nav{
    width: 20%;
    height:100%;
    float: left;
    position: fixed;
    overflow-x: hidden; 
    /* z-index: 1;
    top: 0;
    left: 0; */
}
.maincontent{
    float: middle;
    width:64%;
    height: 100%;
    background-color:  #F5F6FB;
    margin-left: 16%;
}
.subcontent{
    margin: 10px;
    background-color: #F5F6FB;
}

.sidebar{
    float: right;
    width: 20%;
    height: 100%;
    background-color:#E2E5F4;   
    top: 0px;
    
}
.orgbox{
    margin: 20px;
}
.disbox{
    margin: 20px;
}
.org_head{
    margin: 20px;
}

.linechart{
    margin:20px;
}
.keyratiotbl{
    margin: 20px;
}
.keycomptbl{
    margin: 20px;
}

.conatiner-2{
    background-color:  #E2E5F4;
    border-radius: 10px;
    padding: 10px;
    margin: 20px;
}

.heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #5864B4;
    }
.margin-heading{
    color: #5864B4;
    margin-bottom: 15px;
} 