
.topMenu {
display: flex;
flex-direction: row;
flex-wrap:'wrap';
row-gap: 40px;
padding: 10px;
padding-left: 5px;
font-family: 'Poppins';
font-style: normal;
font-size: 13px;
line-height: 20px;
align-items: center;
}
.topMenu:hover{
    color:black;
    font-weight: bold;
    font-size: 13px;
}
.menuImg{
    width: 50px;
}
.tcscore_logo{
margin: 30px 40px 30px 10px;
/* margin-bottom: 30px; */
}
.logo{
    width:100% ;
    height: 100%;
}

.menuLabels {
    cursor: pointer;
}
