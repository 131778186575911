.progress {
    width:58%;
    color: #7772bf;
    margin: 5px;
    /* padding:20px; */
    border-radius: 12px;
    background-color: white;
    height: 80%;
    margin-left: 50px;

}

.CircularProgressbar-path {
    stroke: #7772bf !important;
  }

.probar {
    width:130px;
    height: 130px;
    align-items: center;
}