body{
    width: 100%;
}
.exploreButton {
    height:54px;
    width:100px;
    background-color: #2924A6;
    border-radius: 5px;
    border:transparent;
    color: white;
    cursor: pointer;
}

.MuiInputBase-root .MuiOutlinedInput-root .MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    padding-right: 0%;
   
    
}
.search{
    width: 100%;
    margin: 40px 10px 30px 10px;
}