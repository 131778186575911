body {
  width: 100%;
  height: 100%;
}

nav {
  width: 18%;
  height: 100%;
  float: left;
}

.maincontentbenchmark {
  float: left;
  width: 80%;
  height: 100%;
  background-color: white;
  margin-left: 17%;
}

.subcontent {
  margin: 10px;
  background-color: #F5F6FB;
}

.benchmarkhead {
  padding: 20px;
  background-color: #FFFFFF;

}

.orgcard {
  width: 100px;
  height: 50px;
}

.benchmarkImg {
  width: 50%;
  height: 50%;
  margin-left: 25%;
  margin-top: 12%;
}

.organizationlist td {
  width: 30px;
  height: 10px;
  word-wrap: "break-word";
  border-radius: 10px;
}

.benchMarkIcon {
  width: 80px;
  height: 24px;
  padding: 10px;
  background-color: #F5F6FB;
}

.selectorganizationstyle {
  width: 686px;
  height: 64px;
  margin-left: 49px;
  margin-top: 26px;
  border: 10px;
  color: black;
  background-color: #F5F6FB;
}

.comparefeature {
  width: 80px;
  height: 24px;
  padding: 10px;
  background-color: #F5F6FB;
}

.benchMarkAddItemBtn {
  width: 10px;
  height: 60px;
  padding: 10px;
  background-color: #E2E5F4
}

.progressBar {
  background-color: #e9e7e7;
  padding: 20px;
  width: 50%;
  margin: 20px;
  border-radius: 10px;
}

.addItem {
  border: 0px;
  background-color: transparent;
  cursor: pointer;
}

.popuptitle {
  color: red;
}

.closeButton {
  margin-left: 100px;
  background-color: #5B63B8;
  color: white;
  width: 80px;
  border-radius: 7px;
  height: 30px;
  border-color: #5B63B8;
}

.addOrganizationButton {
  margin-right: 35px;
  margin-bottom: 23px;
  margin-left: 622px;
  margin-top: 120px;
  background-color: #2924A6;
  color: white;
  width: 110px;
  border-radius: 10px;
  height: 60px;
  border-color: #2924A6;
  cursor: pointer;
}

.delete-button {
  width: 40px;
  height: 40px;
  float: right;
  cursor: pointer;
}