body{
    width: 100%;
    height: 100%;
}
.main-content{
    border-radius: 10px;
    /* width: 110%; */
    /* height: 100%; */
    padding: 1px;
    background-color:white;
}
.web-container-1{
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
}

.web-container-1 h3 {
    margin-left: 20px;
    margin-top: 10px;
}
.web-img{
    height: 40px;
    width: 40px;
    margin-left: 24px;
    position: absolute;
    margin-top: 4px;
}
.web-frame{
    height: 50px;
    width: 50px;
    margin-left: 20px;
}

.scrape-data{
    border-radius: 10px;
    padding: 0px;
    margin: 14px;
    text-align: left;
}

.web-heading{
    color: #5864B4;
    margin-left: 20px;
}
