body{
    width: 100%;
}
/* .fin{

    display:flex;
    flex-direction: row;
   
   
} */
/* .summary{
    margin: auto;
    border-radius: 5px;
    background-color:#E2E5F4;
    padding: 10px;
    text-decoration: none;
    color: #5B63B8;

}
.income{
    margin: auto;
    border-radius: 5px;
    background-color:#E2E5F4;
    padding: 10px;
    text-decoration: none;
    color: #5B63B8;
}
.balance{
    margin: auto;
    border-radius: 5px;
    background-color: #E2E5F4;
    padding: 10px;
    text-decoration: none;
    color: #5B63B8;

}
.cashflow{
    margin: auto;
    border-radius: 5px;
    background-color:#E2E5F4;
    padding: 10px;
    text-decoration: none;
    color: #5B63B8;

}
.keyratio{
    margin: auto;
    border-radius: 5px;
    background-color:#E2E5F4;
    padding: 10px;
    text-decoration: none;
    color: #5B63B8;

}
.summary:hover{
    background-color: #5B63B8;
    color: #FFFFFF;
}
.income:hover{
    background-color: #5B63B8;
    color: #FFFFFF;
}
.balance:hover{
    background-color: #5B63B8;
    color: #FFFFFF;
}
.cashflow:hover{
    background-color: #5B63B8;
    color: #FFFFFF;

}
.keyratio:hover{
    background-color: #5B63B8;
    color: #FFFFFF;

} */

.fin {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    border-bottom: 3px solid #ccc;
  }
  
  .fin a {
    display: block;
    padding: 10px;
    margin: 5px;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    color: #2924A6;
  }
  
  .fin a.active {
    font-weight: bold;
    color: black;
    border-bottom-color: black;
  }
  
  @media (max-width: 768px) {
    .fin {
      flex-direction: column;
      align-items: center;
    }
  }
  
  