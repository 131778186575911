.NewsFeed_Card{
    width: 15%;
    margin:auto;
    padding: 10px;
    text-align: left;
    border-radius: 10px;
    background: #E2E5F4;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
}
.NewsFeed {
    display: flex;
    flex-direction: column;
}