.mixedchart{
    padding: 20;
}
.peertable{
    margin-top: 10px;
}
.mixed-chart{
    padding: 10px;
    background-color:#E2E5F4;
    border-radius: 10px;
    margin-top: 25px;
}

.h4{
    font-style: Bold;
    font-size: 20px;
    line-height: 30px;
    line-height: 100%;
    vertical-align: Top;
    color: #5864B4;
    }