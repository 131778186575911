.incomeTab {
    background-color: #e3e2f7;
    margin: 10px;
    border-radius: 10px;
    padding:10px;
}

.incomeTab h2{
    color: #817cd9;
    font-weight: 600;
    margin-bottom: 0px;
}
.incomeTab p{
    margin-top: 0px;
}
.incomeTab button {
    padding:10px;
    border-radius: 10px;
    color: #817cd9;
    border:0px;
    background-color: white;
    font-weight: 600;
    width:100px;
}

.header {
    border:0px;
    background-color: white;
    padding: 25px;
    margin:10px;
    border-radius: 10px;
    font-weight: 600;
}
.incomeTab th {
    border:0px;
    background-color: white;
    padding: 25px;
    margin:10px;
    border-bottom: 10px solid #e3e2f7;
    text-align: left;
}
.years {
    color: #817cd9;
}

.tableVal {
    border:0px;
    background-color: white;
    padding: 25px;
    margin:10px;
    border-radius: 10px;
}

.accTr {
    cursor: pointer;
}
.childTd {
    padding-left: 9px;
}

/* .incomeTab:hover {
    background-color: #e3e2f7;
} */
.parentInc {
    display: flex;
    justify-content: space-between;
  }
.childInc1 ,.childInc2{
padding-top:30px
}
.childInc1 {
    margin-left: 35%;
}

.incomeTab button {
    cursor: pointer;
}
.incomeTab button:active{
    background-color: #817cd9;
    color: white;
}