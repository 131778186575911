

.flex-main-container{
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  max-width: 800px;
  min-width:650px;
  align-items: center;
  overflow: auto;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.card {
  width: 29%;
  height: 60px;
  background-color: #F5F6FB;
  margin-bottom: 20px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: column;
  margin: 8px;
  border-radius: 10px;
  padding: 5px;
}
.card p{
  margin: 0%;
  color : #888888;
}
.card h3{
  margin: 0%;
  color : black;
  font-weight: 900;
  font-size: 18px;
}
.flex-title h3{
margin: 0%;
}
/* @media screen and (max-width: 768px) {
  .card {
    width: 45%;
  }
} */

.flex-title{
  color: #2924A6;
  padding: 0px;
  margin: 0%;
  margin-left: 10px;
}
/* @media screen and (max-width: 480px) {
  .card {
    width: 100%;
    
  }
} */
