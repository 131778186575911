.main-container{
    /* width: 100%; */
    background-color: #E2E5F4;
    margin: 10px;
    border-radius: 10px;
    margin: 1;
    padding: 20px;
}
.circular-container{
    background-color: white;
    padding-bottom: 50px;
    border-radius: 8px;
    width: 40%;
}
.source-container{
    background-color: white;
    border-radius: 10px;
    margin-left: 25px;
    width: 60%;
    /* height: 30%; */
}
.rowC{display:flex; flex-direction:row;}