.keyTab {
    background-color:white;
    margin: 0px;
    border-radius: 10px;
    padding:10px;
    margin-top: 10px;
}

.keyTab h4{
    color: #817cd9;
    font-weight: 600;
    margin-bottom: 0px;
}

.keyTab th {
    border:0px;
    background-color: white;
    padding: 7px;
    margin:10px;
    border-bottom: 2px solid #ccc;
    text-align: left;
}