
body{
    width: 100%;
    height: 100%;
}

.Bulletin{
    background-color:white;
}

.bulletinTop{
margin: 20px;
padding: 10px;
font-family: 'Poppins';
font-style: normal;
font-size: 16px;
height: 100%;
word-wrap: break-word;
}
.bulletinHeader{
    font-weight: bold;
}
.BulletinLogo{
    width:220px;
     height:100px;
     
    margin: 20px;
    border-radius: 10px;
    background-color: #EAECF8;
}
.bulletinlogo{
    width: 180px;
    height: 50px;
    margin: 20px;
    
}


.bull-sidebar {
    
    background-color: #f0f0f0;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .bull-sidebar li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .bull-sidebar-label {
    flex: 1;
    text-align: left;
  }
  
  .bull-sidebar-value {
    flex: 10;
    text-align: right;
  }
  