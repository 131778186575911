body{
    width: 100%;
}
.tcsLogo{
    /* padding-top: 5px; */
    text-align: center;
}

.logoTagline{
    margin-top: 30px;
    width: 493px; 
    height:156px;
}
.logo_caption{
font-size: medium;
text-align: Center;
margin-top: 1px;
font-family:'OpenSans'
}

.core_logo{
    line-height: 0.1em;
}

.recommendations {
    display: inline-block;
    text-align: left;
    font-weight: bold;
    position: absolute;
    left: 278px;
    width: 60%;
}
.recom_text{
    margin-left:70px ;
}
.img_div{
    background-color: #EAECF8;
    width: 155px;
    height: 100px;
    margin: 5px;
    border-radius: 10px;
   
}
.logo_text{
    font-size: 11px;
    text-align: center;
    font-style: normal;
    font-family:'OpenSans'
    
    
}

.cmpImg {
    width: 120px;
    height: 100px;
    cursor: pointer;
    margin-left: 20px;
}

.imgList {
    display: flex;
    gap: 10px;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    
   

}

