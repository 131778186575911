
/* body,
html {
  font-family: 'Poppins', sans-serif;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./Fonts/Poppins-Regular.ttf) format('truetype');
} */

body,
html,div {
  font-family: 'OpenSans', sans-serif;
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), 
  url(./Fonts/OpenSans-Regular.ttf) format('truetype');
}

.css-ahj2mt-MuiTypography-root {
  font-family: 'OpenSans';
}





