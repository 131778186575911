.resorce-container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    /* padding: 16px; */
  }
  
  .dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .form input[type="text"],
  .form input[type="file"] {
    margin-bottom: 16px;
  }
  
  .form input[type="file"] {
    display: none;
  }
  
  .form label[for="file-upload"] {
    cursor: pointer;
  }
  
  .form button[type="submit"] {
    margin-top: 16px;
  }
  