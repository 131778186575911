body{
    width: 100%;
    height: 100%;
}
nav{
    width: 20%;
    height:100%;
    float: left;
   
}
.maincontent{
    float: left;
    width:60%;
    height: 100%;
    background-color:rgb(241, 247, 250);
    border-radius: 5px;
}
.subcontent{
    margin: 10px;
    
}



.sidebar{
    float: right;
    width: 20%;
    border-radius: 0px 10px 10px 0px;
   max-height:max-content;
   background-color:rgb(221, 232, 244);
    
}


.searchbar .MuiTextField-root{
    width: -webkit-fill-available;
    margin-right: 5%;
}

.total-visits-chart{
    padding: 10px;
    background-color:white;
    border-radius: 10px;
    margin-top: 15px;
}
.market-channel-chart{
    padding: 10px;
    background-color:white;
    border-radius: 10px;
    margin-top: 10px;
    

}
.social-network-chart{
    padding: 10px;
    background-color:white;
    border-radius: 10px;
    margin-top: 15px;
}

.chart-container{
padding: 20px;
background-color: white;
border-radius: 10px;
}

.h4{
font-style: Bold;
font-size: 18px;
line-height: 30px;
line-height: 100%;
vertical-align: Top;
color: #5864B4;
margin-bottom: 0px;
}

.digbox{
    padding:10px
}