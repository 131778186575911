table {
    width: 100%;
}
tr:nth-child(even) {
    background-color: white;
}

td {
    border: none !important;
    padding: 10px;
}