

   .fin-flex-main-container{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .fin-flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }
   .card {
    width: 29%;
    height: 60px;
    background-color: #F5F6FB;
    margin-bottom: 20px;
    display: flex;
    justify-content: left;
    margin: 8px;
    border-radius: 10px;
    padding: 5px;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;
  }
  .card h3{
  margin: 0%;}
  @media screen and (max-width: 768px) {
    .card {
      width: 45%;
    }
  }
  
  .fin-flex-titles{
    color: #2924A6;
    padding: 0px;
  }
  .year-data{
    color:black;
    font-size: 14px;
  }
  .fin-flex-titles h3{
    margin: 0%;
    }
  @media screen and (max-width: 480px) {
    .card {
      width: 100%;
    }
  }
  