body{
    width: 100%;
    height: 100%;
}
nav{
    width: 20%;
    height:100%;
    float: left;
   
}

.maincontent{
    float: left;
    width:60%;
    height: 100%;
background-color:  #F5F6FB;
}
.subcontent{
    margin: 10px;
    background-color: #F5F6FB;
}

.sidebar{
    float: right;
    width: 20%;
    height: 100%;
    background-color: #F5F6FB;    
    
}

.financial{
    margin: 20px;
}





