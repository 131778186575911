
.graphContainer {
    display: flex;
    justify-content: space-between;
  }
  
  @media (max-width: 1067px) {
    .graphContainer {
      flex-direction: column;
      align-items: center;
    }
  }
  