.spinner-border {
    display: inline-block;
    width: 8rem;
    height: 8rem;
    vertical-align: -.125em;
    border: .5em dotted currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border;
}

@-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

.message {
    position: absolute;
    font-size: 25px;
}