.container {
    width: 100%;
    background-color:  #E2E5F4;
    border-radius: 10px;
    /* padding: 20px; */
    /* margin: 20px; */
  }

.heading-key-exe{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #5864B4;
  margin-top: 25px;
  padding-top: 1px;
  }
  
  .tables{
    padding: 20px;
  }
  
  .headers {
    background-color: white;
    color: #5864B4;
    padding: 5px;
    margin: 0px;
    margin-bottom: 0px;
  }
  .heading h4{
    margin-bottom: 0px;
  }
  .header-kyc{
    text-align:left;
  }
  .td-data{
    margin-top: 20px;
    background-color: white;
    /* padding: 20px; */
    padding: 15px;
    text-align:left;
  }
  .kyc-data{
    background-color: white;
    padding: 10px;
    width: 270px;
    text-align: left;
  }
  .table-row {
    border-bottom: 1px solid #ccc;
  }
  
  .table-cell {
    background-color: #fff;
  }
  
  
  
  
  
  
  
  
  